<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>durees </label>
          <input type="text" :class="errors.durees?'form-control is-invalid':'form-control'"
                 v-model="form.durees">

          <div class="invalid-feedback" v-if="errors.durees">
            <template v-for=" error in errors.durees"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>date </label>
          <input type="text" :class="errors.date?'form-control is-invalid':'form-control'"
                 v-model="form.date">

          <div class="invalid-feedback" v-if="errors.date">
            <template v-for=" error in errors.date"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>deplacements </label>
          <CustomSelect
              :key="form.deplacement"
              :url="`${axios.defaults.baseURL}/api/deplacements-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.deplacement"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.deplacement_id=data.id;form.deplacement=data}"
          />
          <div class="invalid-feedback" v-if="errors.deplacement_id">
            <template v-for=" error in errors.deplacement_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>sites </label>
          <CustomSelect
              :key="form.site"
              :url="`${axios.defaults.baseURL}/api/sites-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.site"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.site_id=data.id;form.site=data}"
          />
          <div class="invalid-feedback" v-if="errors.site_id">
            <template v-for=" error in errors.site_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateSitessdeplacements',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'deplacementsData',
    'sitesData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        deplacement_id: "",

        site_id: "",

        durees: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",

        date: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/sitessdeplacements', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        deplacement_id: "",
        site_id: "",
        durees: "",
        creat_by: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        date: "",
      }
    }
  }
}
</script>
